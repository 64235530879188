import { createRouter, createWebHistory } from 'vue-router'
import { loggedIn } from '@/store/modules/moduleUser'

import i18n from './i18n'
import Login from './pages/Login.vue'
import SignUp from './pages/SignUp.vue'
import Dashboard from './pages/Dashboard.vue'
import Invite from './pages/Invite.vue'
import Invitations from './pages/Invitations.vue'
import Members from './pages/Members.vue'
import SurveyResults from './pages/SurveyResults.vue'
import Survey360Results from './pages/Survey360Results.vue'
import AnswerSurvey from './pages/AnswerSurvey.vue'
import SendSurvey from './pages/SendSurvey.vue'
import PageNotFound from './pages/404.vue'
import UserResults from './pages/UserResults'
import UserSurveyAnswers from './pages/UserSurveyAnswers'
import AllMembers from '@/pages/AllMembers'
import ResetPassword from '@/pages/ResetPassword'
import ConfirmNewPassword from '@/pages/ConfirmNewPassword'
import SurveyDetails from '@/pages/SurveyDetails'
import Organization from '@/pages/Organization'
import AnswerSurvey360 from '@/pages/AnswerSurvey360'
import DataProtection from '@/pages/DataProtection'

// All routes require Authentication by default,
// specify otherwise with meta: noRequiresAuth true
// meta.navPath defines the navigation path to use in
//     the header. Make sure each element is a name
const routes = [
  {
    path: "/data-protection",
    name: "data-protection",
    component: DataProtection,
    meta: {
      noRequiresAuth: true,
      navPath: [],

    },
  },
  {
    path: "/signup/:token",
    name: "signup",
    component: SignUp,
    meta: {
      noRequiresAuth: true,
      navPath: []
    },
  },
  {
    path: "/login",
    name: "login",
    component: Login,
    meta: {
      noRequiresAuth: true,
      navPath: []
    }
  },
  {
    path: "/org/:org",
    name: "organization",
    component: Organization,
    meta: {
      navPath: ["organization"]
    }
  },
  {
    path: "/org/:org/invite",
    name: "invite",
    component: Invite,
    props: true,
    meta: {
      navPath: ["organization", "invite"]
    }
  },
  {
    path: "/org/:org/invitations",
    name: "invitations",
    component: Invitations,
    props: true,
    meta: {
      navPath: ["organization", "invitations"]
    }
  },
  {
    path: "/org/:org/:group/dashboard",
    name: "members",
    component: Members,
    meta: {
      navPath: ["organization", "members"]
    }
  },
  {
    path: "/org/:org/members",
    name: "allMembers",
    component: AllMembers,
    meta: {
      navPath: ["organization", "allMembers"]
    }
  },
  {
    path: "/org/:org/members/:usr",
    name: "member-results",
    component: UserResults,
    meta: {
      navPath: ["organization", "allMembers", "member-results"]
    }
  },
  {
    path: "/org/:org/survey/respond/:id",
    name: "answerSurvey",
    component: AnswerSurvey,
    meta: {
      navPath: []
    }
  },
  // FIXME: this is a really bad name: surveyView for AnswerSurvey
  {
    path: "/org/:org/survey/:id/view",
    name: "surveyView",
    component: AnswerSurvey,
    meta: {
      noRequiresAuth: true,
      navPath: ["organization", "surveyView"]
    },
  },
  {
    path: "/org/:org/survey/send",
    name: "sendSurvey",
    component: SendSurvey,
    props: true,
    meta: {
      navPath: ["organization", "sendSurvey"]
    }
  },
  {
    path: "/org/:org/surveys/:id",
    name: "surveyResults",
    component: SurveyResults,
    meta: {
      navPath: ["organization", "surveyResults"]
    }
  },
  {
    path: "/user/:usr",
    name: "userResults",
    component: UserResults,
    meta: {
      navPath: ["userResults"]
    }
  },
  {
    path: "/user/:usr/answerset/:id/survey/:survey",
    name: "userSurveyAnswers",
    component: UserSurveyAnswers,
    meta: {
      navPath: ["userResults", "surveyResults"]
    }
  },
  {
    path: "/",
    name: "dashboard",
    component: Dashboard,
    meta: {
      navPath: []
    }
  },
  // FIXME: old school route, clear after summer 2024
  {
    path: "/organization/:organization",
    redirect: to => {
      return { name: 'organization', 'params': { 'org': to.params.organization } }
    }
  },
  {
    path: "/login/reset-password/",
    name: "resetPassword",
    component: ResetPassword,
    meta: {
      noRequiresAuth: true,
      navPath: []
    },
  },
  {
    path: "/login/new-password",
    name: "confirmNewPassword",
    component: ConfirmNewPassword,
    meta: {
      noRequiresAuth: true,
      navPath: []
    },
  },
  {
    path: "/org/:org/survey-reminder",
    name: "surveyDetails",
    component: SurveyDetails,
    meta: {
      navPath: ["organization", "survey-reminder"]
    },
  },

  // FIXME: this returns 200. We should do the 404 somewhere else than frontend
  {
    path: "/:catchAll(.*)*",
    name: "PageNotFound",
    component: PageNotFound,
  },
  {
    path: "/org/:org/survey360s/:id",
    name: "survey360Results",
    component: Survey360Results,
  },
  {
    path: "/org/survey360/respond/:id",
    name: "answerSurvey360",
    component: AnswerSurvey360,
    meta: {
      noRequiresAuth: true
    },
  }
];

const router = createRouter({
  history: createWebHistory(),
  scrollBehavior(to, from, savedPosition) {
    // Always scroll to top page
    return { top: 0 }
  },
  routes,
})

router.beforeEach((to, from, next) => {
  if (to.meta.navPath?.length > 0) {
    const lastbit = to.meta.navPath[to.meta.navPath.length - 1]
    const translatedTitle = i18n.global.t(`default.navigation.${lastbit}`)
    document.title = `Selfleader.io > ${translatedTitle}`
  } else {
    document.title = 'Selfleader.io'
  }

  if (!to.matched.some(record => record.meta.noRequiresAuth)) {
    if (!loggedIn()) {
      next({
        path: '/login',
        query: { redirect: to.fullPath }
      })
    } else next()
  } else next()

})

export default router
