<template>
  <div class="category-summary-360 p-3 py-5 items-center w-full justify-between flex-row">
    <h4>{{ translator(clang, category) }}</h4>
    <div class="flex flex-wrap items-baseline flex-row w-full justify-between w-full">
      <div class="w-full md:w-1/2 pr-1">
        <h5>
          {{ $t('default.survey.strongest') }}
        </h5>
        <div class="question mb-2 flex flex-row w-full justify-between items-center md:align-middle"
          v-for="(question, i) in topQuestions" :key="`cq-${i}`">
          <span class="flex w-2/5 text-xs md:text-sm text-gray-700 h-auto md:h-28 items-center">
            {{ translator(clang, question) }}
          </span>
          <div class="w-3/5">
            <div v-for="(average, name) in question['average']" :key="name" class="role-container">
              <label>
                {{ name }}
              </label>
              <div class="chart">
                <BarChart :value="average" :max="question['max']" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="w-full md:w-1/2 pl-1">
        <h5>
          {{ $t('default.survey.weakest') }}
        </h5>
        <div class="question mb-2 flex md:flex-row-reverse w-full justify-between items-center"
          v-for="(question, i) in bottomQuestions" :key="`cq-${i}`">
          <span class="flex w-2/5 text-xs md:text-sm text-gray-700 h-auto md:h-28 items-center">
            {{ translator(clang, question) }}
          </span>
          <div class="w-3/5">
            <div v-for="(average, name) in question['average']" :key="name" class="role-container">
              <label>
                {{ name }}
              </label>
              <div class="chart">
                <BarChart :value="average" :max="question['max']" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BarChart from '@/components/BarChart'
import translator from "../mixins/translator";

export default {
  name: 'CategoryResults',
  components: {
    BarChart
  },
  props: {
    category: {
      required: true,
      default: null
    },
    clang: {
      default: 'fi'
    },
  },
  mixins: [translator],
  computed: {
    sortedQuestions() {
      if (!this.category) return
      let questions = Object.values(this.category['questions'])
      return questions.sort((span, b) => span['average'] <= b['average'] ? 1 : -1)
    },
    displaySummary() {
      let s = this.category['display_summary']
      if (this.sortedQuestions.length / 2 < s)
        s = Math.ceil(this.sortedQuestions.length / 2)
      return s
    },
    topQuestions() {
      return this.sortedQuestions.slice(0, this.displaySummary)
    },
    bottomQuestions() {
      return this.sortedQuestions.slice(this.displaySummary, this.sortedQuestions.length).reverse()
    },
    wideWidth() {
      const avg = this.topQuestions.map(ele => ele['average'])
      for (let ele of avg) {
        if (ele % 1 === 0) {
          return 'w-20'
        }
      }
      return 'w-24'
    },
  },
  methods: {
    ifDecimal(number) {
      if (number !== null) {
        if (number % 1 === 0) {
          return number.toFixed(0);
        }
        return number.toFixed(2)
      }
    },
  }
}

</script>

<style lang="scss">
.category-summary-360 {

  // strongest & weakest
  h5 {
    text-align: center;
    font-size: 1rem;
    margin: 10px 0;

    @media screen and (max-width: 768px) {
      text-align: left;
      margin-top: 10px;
    }
  }

  .role-container {
    display: flex;
    margin: 0 10px;

    label {
      font-size: 14px;
      flex-basis: 60px;
      overflow: hidden;
      margin: 0 5px;
    }

    .chart {
      flex: 1;
    }

    &:nth-child(2) {
      .chart {
        .background {
          .bar {
            background: var(--halsa-blue);
          }
        }
      }
    }
  }

  .question:nth-child(2) {
    // background: var(--gray-100);
  }

  &:nth-child(2) {
    background: var(--gray-200);
  }
}
</style>
