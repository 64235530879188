<script setup>
import { computed } from 'vue'

import DropDown2 from '@/components/DropDown2.vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()

const selectedGoal = defineModel("selectedGoal")
const selectedTask = defineModel("selectedTask")

const props = defineProps({
  goals: Array,
  tasks: Array,
})

const selectedGoalNameAndRepetiton = computed(() => {
  const goal = props.goals.filter(g => g.id===selectedGoal.value).at(0)
  if (!goal) {
    return null
  }

  const repetition = t(`default.invitations.goalsandtasks.goals.${goal.repetition_rate	}`)

  return {
    name: goal.name,
    repetition,
  }
})

const emit = defineEmits(['click:addGoal', 'click:addTask'])
</script>

<template>
  <h3>{{ $t('default.invitations.goalsandtasks.title') }}</h3>
  <p>{{ $t('default.invitations.goalsandtasks.ingress') }}</p>

  <div class="content-container">
    <div class="title">{{ $t('default.invitations.goalsandtasks.goals.title') }}</div>
    <div class="content">
      <drop-down-2
        :options="goals"
        v-model="selectedGoal"
        :placeholder="$t('default.invitations.goalsandtasks.goals.select')"
        class="w-9/12"
      />
      <div class="text-gray-400 text-sm mt-1 flex align-center flex items-center">
        <font-awesome-icon icon="fa-solid fa-circle-check" />
        <template v-if="selectedGoalNameAndRepetiton">
          &nbsp;{{ $t('default.invitations.goalsandtasks.goals.info', { 
            name: selectedGoalNameAndRepetiton.name,
            repetition: selectedGoalNameAndRepetiton.repetition }) }}
        </template>
      </div>
      <button class="green" @click="emit('click:addGoal')" :disabled="!selectedGoal">
        {{ $t('default.invitations.goalsandtasks.goals.add') }}
      </button>
    </div>

    <div class="title">{{ $t('default.invitations.goalsandtasks.tasks.title') }}</div>
    <div class="content">
      <drop-down-2 
        :options="tasks"
        v-model="selectedTask"
        :placeholder="$t('default.invitations.goalsandtasks.tasks.select')"
        class="w-9/12"
      />
      <div class="text-gray-400 text-sm mt-1 flex align-center flex items-center">
        <font-awesome-icon icon="fa-solid fa-list-check" />
        &nbsp;{{ $t('default.invitations.goalsandtasks.tasks.info') }}
      </div>
      <button class="green" @click="emit('click:addTask')" :disabled="!selectedTask">
        {{ $t("default.invitations.goalsandtasks.tasks.add") }}
      </button>
    </div>
  </div>
</template>
