import axios from '@/axios-instance'

const defaultState = () => ({
  goals: [],
  tasks: [],
  selectedSurvey: null,
  selectedGoal: null,
  selectedTask: null,
  selectedGoals: [],
  selectedTasks: [],
  selectedGroup: undefined,
  selectedMembers: [],
  newMembers: [],
  additionalMessage: "",
  surveyAdditionalMessage: "",
  selectedDate: null,
})

export const ModuleInvitations = {
  namespaced: true,

  state: {
    ...defaultState(),
  },

  getters: {
    goalsForDropdown: (state) => (
      state.goals
        .filter(g => !state.selectedGoals.includes(g.id))
    ),
    tasksForDropdown: (state) => (
      state.tasks
        .filter(t => !state.selectedTasks.includes(t.id))
    ),
    goalsForSelectedList: (state) => (
      state.goals
        .filter(g => state.selectedGoals.includes(g.id))
    ),
    tasksForSelectedList: (state) => (
      state.tasks
        .filter(t => state.selectedTasks.includes(t.id))
    ),
  },

  mutations: {
    setGoals(state, data) {
      state.goals = data
    },
    setTasks(state, data) {
      state.tasks = data
    },
    selectGoal(state, goalId) {
      state.selectedGoal = goalId
    },
    selectTask(state, taskId) {
      state.selectedTask = taskId
    },
    addGoal(state) {
      if (state.selectedGoal) {
        state.selectedGoals.push(state.selectedGoal)
        state.selectedGoal = null
      }
    },
    addTask(state) {
      if (state.selectedTask) {
        state.selectedTasks.push(state.selectedTask)
        state.selectedTask = null
      }
    },
    removeGoal(state, goalId) {
      const idx = state.selectedGoals.indexOf(goalId)
      if (idx > -1) {
        state.selectedGoals.splice(idx, 1)
      }
    },
    removeTask(state, taskId) {
      const idx = state.selectedTasks.indexOf(taskId)
      if (idx > -1) {
        state.selectedTasks.splice(idx, 1)
      }
    },
    selectGroup(state, groupId) {
      state.selectedGroup = groupId
    },
    selectSurvey(state, surveyId) {
      state.selectedSurvey = surveyId
    },
    clearSurvey(state) {
      state.selectedSurvey = null
    },
    addMember(state, memberId) {
      if (!state.selectedMembers.includes(memberId)) {
        state.selectedMembers.push(memberId)
      }
    },
    removeMember(state, memberId) {
      const idx = state.selectedMembers.indexOf(memberId)
      if (idx > -1) {
        state.selectedMembers.splice(idx, 1)
      }
    },
    addNewMember(state, { email, groupId }) {
      state.newMembers.push({
        email,
        groupId,
      })
    },
    removeNewMember(state, memberIdx) {
      if (memberIdx > -1) {
        state.newMembers.splice(memberIdx, 1)
      }
    },
    setAdditionalMessage(state, msg) {
      state.additionalMessage = msg
    },
    setSurveyAdditionalMessage(state, msg) {
      state.surveyAdditionalMessage = msg
    },
    setSelectedDate(state, date) {
      state.selectedDate = date
    },
    resetState(state) {
      Object.assign(state, defaultState())
    },
  },

  actions: {
    fetchGoals(context) {
      return axios.get('/api/v1/tasksgoals/goals/')
        .then((res) => {
          context.commit('setGoals', res.data)
        })
        .catch((err) => {
          console.log(err)
        })
    },
    fetchTasks(context) {
      return axios.get('/api/v1/tasksgoals/tasks/')
        .then((res) => {
          context.commit('setTasks', res.data)
        })
        .catch((err) => {
          console.log(err)
        })
    },
  },
}

//const manageTask = (context, userTask, action, note) => {
//  return axios.put(`/api/v1/tasksgoals/usertasks/${userTask.id}`, { action, note })
//    .then((res) => {
//      context.dispatch("fetchUserTasks", { usr: res.data.user_id })
//    })
//    .catch((err) => {
//      console.log(err)
//    })
//}
//
//const manageGoal = (context, userGoal, action, note) => {
//  return axios.put(`/api/v1/tasksgoals/usergoals/${userGoal.id}`, { action, note })
//    .then((res) => {
//      context.dispatch("fetchUserGoals", { usr: res.data.user_id })
//    })
//    .catch((err) => {
//      console.log(err)
//    })
//}