<template>
  <div class="single-value-user-result flex items-center w-full justify-between"
    :class="`${question['answers'][0]['id'] % 2 ? 'odd' : 'even'}`">
    <div class=" flex px-3 py-5 items-center w-full justify-between">
      <div class="w-2/5">
        {{ translator(clang, question) }}
      </div>
      <BarChart class="w-3/5" :showValue="true" :percentage="proportionalWidth" :value="question['answers'][0]['value']"
        :clang="clang" :even="question['answers'][0]['id'] % 2 === 0"></BarChart>
    </div>
  </div>
</template>

<script>

import translator from "../mixins/translator";
import BarChart from "./BarChart.vue";

export default {
  name: 'SingleValueUserResult',
  props: ['question', 'index', 'clang'],
  components: { BarChart },
  mixins: [translator],
  computed: {
    proportionalWidth() {
      if (this.question.intervals) {
        return (this.question.answers[0].value / this.question.intervals.slice(-1))
      }
      return this.question.answers[0].value / 100.0
    }
  },
}

</script>
