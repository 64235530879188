<script setup>
import DropDown2 from '@/components/DropDown2.vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

const selectedGroup = defineModel("selectedGroup")
const newMembers = defineModel("newMembers")

const props = defineProps({
  groups: Array,
  members: Array,
  newMembersHasErrors: Boolean,
})

const emit = defineEmits(['addMember', 'addNewMembers'])
</script>

<template>
  <h3>{{ $t('default.invitations.recipients.title') }}</h3>
  <p>{{ $t('default.invitations.recipients.ingress') }}</p>

  <div class="content-container">
    <div class="title">{{ $t('default.invitations.recipients.group-title') }}</div>
    <div class="content">
      <drop-down-2 
        :options="groups"
        v-model="selectedGroup"
        class="w-full"
      />
    </div>

    <div class="title">{{ $t('default.invitations.recipients.members-title') }}</div>
    <div class="content">
      <div class="members border border-gray-300 bg-gray-100 w-full">
        <div 
          v-for="member in members"
          :key="`member-${member.id}`"
          class="member hover:bg-gray-200"
          @click="emit('addMember', member.id)"
        >
          <span>{{ member.first_name }} {{ member.last_name }}</span>
          <div 
            class="add-button"
          >
            <font-awesome-icon icon="fa-solid fa-plus-circle" />
          </div>
        </div>
      </div>
    </div>

    <div class="title">{{ $t('default.invitations.recipients.invite-title') }}</div>
    <div class="content">
      <div class="invite-members-container" :class="{error: newMembersHasErrors}">
        <textarea
          class=""
          v-model="newMembers"
        >
        </textarea>
        <button class="green" @click="emit('addNewMembers')">
          {{ $t("default.invitations.recipients.add-button") }}
        </button>
      </div>
      <div class="text-gray-400 text-sm mt-1 flex align-center flex items-center">
        {{ $t('default.invitations.recipients.new-members-info') }}
      </div>
    </div>
  </div>
</template>

<style>
.members {
  height: 7em;
  overflow-x: scroll;

  .member {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    padding: 0.3em 0.4em;

    .add-button {
      color: var(--halsa-green);
    }
  }
}

.invite-members-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 1em;

  &.error {
    border: 1px solid red;
  }

  textarea {
    height: 6em;
    flex-grow: 1;
  }
}
</style>