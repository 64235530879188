import axios from "@/axios-instance";

/**
 * Merge the translations to the surveys so that the current language attributes are available
 * on the top level.
 */
const mergeActiveTranslationToSurvey = (data, currentLanguage) => {
  return data.map((survey) => {
    const translationsWithCurrentLanguage = survey.translations.filter(
      (translation) => translation.locale === currentLanguage
    );
    const activeTranslation =
      translationsWithCurrentLanguage.length > 0
        ? translationsWithCurrentLanguage[0]
        : survey.translations[0];
    return { ...survey, ...activeTranslation };
  });
};

export const ModuleSurvey = {
  namespaced: true,

  state: {
    currentLanguage: "fi",
    all: [],
    organizations: {},
  },

  getters: {
    getOrganizationSurveys: (state) => state.organizations,
  },

  mutations: {
    setLang(state, lang) {
      state.currentLanguage = lang;

      // refresh our internal data
      if (state.all.length > 0) {
        this.commit("survey/setSurveys", state.all);
      }
      if (Object.keys(state.organizations).length > 0) {
        Object.keys(state.organizations).map((orgId) => {
          this.commit("survey/setSurveysForOrg", {
            orgId,
            data: state.organizations[orgId],
          });
        });
      }
    },

    setSurveys(state, data) {
      state.all = mergeActiveTranslationToSurvey(data, state.currentLanguage);
    },

    setSurveysForOrg(state, { orgId, data }) {
      const dataMergedTranslation = mergeActiveTranslationToSurvey(
        data,
        state.currentLanguage
      );
      const newState = {
        ...state.organizations,
        [orgId]: dataMergedTranslation,
      };
      state.organizations = newState;
    },
  },

  actions: {
    fetchAll({ commit }) {
      return axios
        .get(`/api/v1/surveys/all/`)
        .then((res) => {
          const data = res.data;
          commit("setSurveys", data);
        })
        .catch((e) => {
          console.log(e);
        });
    },
    fetchForOrganization({ commit }, orgId) {
      return axios
        .get(`/api/v1/surveys/organization/${orgId}/`)
        .then((res) => {
          const data = res.data;
          commit("setSurveysForOrg", { orgId, data });
          return data;
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
};
