<script setup>
import axios from '@/axios-instance'
import { computed, onMounted, ref } from 'vue'
import Alerts from '@/components/Alerts'
import Datepicker from '@vuepic/vue-datepicker'
import MainViewContainer from '@/components/MainViewContainer'
import SelectSurvey from '@/components/invitations/SelectSurvey'
import SelectGoalsAndTasks from '@/components/invitations/SelectGoalsAndTasks'
import SelectRecipients from '@/components/invitations/SelectRecipients.vue'
import ItemList from '@/components/invitations/ItemList.vue'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import { format } from 'date-fns'

const store = useStore()
const locale = useI18n()
const route = useRoute()

const submitError = ref(false)
const submitSuccess = ref(false)
const errorMsg = ref("")
const successMsg = ref("")

const fetchData = async () => {
  const orgId = route.params.org

  store.dispatch('invitations/fetchGoals')
  store.dispatch('invitations/fetchTasks')
  store.dispatch('organization/fetchOrganization', orgId)
  store.dispatch('organization/fetchMembers', orgId)
  const groups = await store.dispatch('organization/fetchGroups', orgId)
  store.commit('invitations/selectGroup', groups.at(0)?.id)
  store.dispatch('survey/fetchAll')
}

onMounted(fetchData)

const selectedGoalsAndTasks = computed(() => {
  const goals = store.getters['invitations/goalsForSelectedList'].map(g => (
    {
      id: g.id,
      name: `${locale.t('default.invitations.goalsandtasks.goals.title')}: ` + g[`title_${locale.locale.value}`],
      removeFn: () => store.commit('invitations/removeGoal', g.id),
    }
  ))
  const tasks = store.getters['invitations/tasksForSelectedList'].map(t => (
    {
      id: t.id,
      name: `${locale.t('default.invitations.goalsandtasks.tasks.title')}: ` + t[`title_${locale.locale.value}`],
      removeFn: () => store.commit('invitations/removeTask', t.id),
    }
  ))
  return goals.concat(tasks)
})

const selectedRecipients = computed(() => {
  if (!store.state.organization.groups || !store.state.organization.members) {
    return []
  }

  const groups = {}
  const memberMap = {}
  for (const group of store.state.organization.groups) {
    groups[group.id] = group.name
  }
  for (const member of store.state.organization.members) {
    memberMap[member.id] = member
  }

  const members = store.state.invitations.selectedMembers.map(mId => {
    const m = memberMap[mId]
    return {
      id: m.id,
      name: `${m.first_name} ${m.last_name} (${groups[m.organization_group]})`,
      removeFn: () => store.commit('invitations/removeMember', m.id),
    }
  })

  const newMembers = store.state.invitations.newMembers.map((m, idx) => ({
    name: `${m.email} (${groups[m.groupId]})`,
    new: true,
    removeFn: () => store.commit('invitations/removeNewMember', idx),
  }))

  return members.concat(newMembers)
})

const newMembers = ref("")
const newMembersHasErrors = ref(false)

const validateEmail = (email) => (
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(email)
)

const handleAddNewMembers = () => {
  const emails = newMembers.value
    .split(/\r?\n/)                 // split by new line
    .flatMap(v => v.split(','))     // split by comma
    .map(s => s.replace(/ /g,''))   // remove whitespace
    .filter(s => s.length > 0)      // filter empty lines
  
  if (!emails.every(e => validateEmail(e))) {
    newMembersHasErrors.value=true
    return
  }

  const groupId = store.state.invitations.selectedGroup

  newMembersHasErrors.value=false
  for (const email of emails) {
    store.commit('invitations/addNewMember', { email, groupId })
  }

  newMembers.value = ''
}

const surveysForDropdown = computed(() => (
  store.state.survey.all
    .filter(s => s.translations.length > 0)       // Filter out potentially misconfigured surveys
    .map(s => {
      const lngOptions = s.translations.map(t => t.locale)
      const l = lngOptions.includes(locale.locale.value) ?
        locale.locale.value :
        lngOptions.includes('en') ? 'en' : lngOptions[0]
      return {
        id: s.id,
        name: s.translations.filter(t => t.locale === l)[0].name,
        questionCount: s.questions_count,
      }})
))

const nativeSelectedDate = computed(() => {
  if (store.state.invitations.selectedDate)
    return format(store.state.invitations.selectedDate, "dd.MM.yyyy H:mm")
  return null
})

const submit = async () => {
  const reqData = {
    organization: route.params.org,
    existing_users: store.state.invitations.selectedMembers,
    new_users: store.state.invitations.newMembers.map(nm => ({email: nm.email, group: nm.groupId})),
    new_users_greeting: store.state.invitations.additionalMessage,
    survey: store.state.invitations.selectedSurvey,
    survey_message: store.state.invitations.surveyAdditionalMessage,
    goals: store.state.invitations.selectedGoals,
    tasks: store.state.invitations.selectedTasks,
    scheduled_for: store.state.invitations.selectedDate,
  }

  try {
    const { data } = await axios.post('/api/v1/organizations/invitation-center/', reqData)
    submitError.value = false
    submitSuccess.value = true
    let tmp = locale.t('default.invitations.success.success')
    if (data.deleted_invitations.length > 0) {
      tmp = tmp + locale.t(
        'default.invitations.success.deleted-invitations',
        {deletedInvitations: data.deleted_invitations},
      )
    }
    successMsg.value = tmp
    store.commit('invitations/resetState')
    await fetchData()

  } catch(err) {
    submitError.value = true
    submitSuccess.value = false
    if (err.response) {
      console.log(err.response)
      errorMsg.value = err.response.data.errors ?? locale.t('default.invitations.error.unknown')
    }
  }
}
</script>

<template>
  <MainViewContainer :showBackIcon="true" :whereToGo="whereToGo" class="invitations-view">
    <h1 class="col-span-12">{{ $t('default.invitations.title') }}</h1>

    <section>
      <div class="half">
        <select-survey 
          :surveys="surveysForDropdown"
          :selected-survey="store.state.invitations.selectedSurvey"
          @update:selectedSurvey="(sId) => store.commit('invitations/selectSurvey', sId)"
          :survey-additional-message="store.state.invitations.surveyAdditionalMessage"
          @update:surveyAdditionalMessage="(msg) => store.commit('invitations/setSurveyAdditionalMessage', msg)"
          @clear-survey="() => store.commit('invitations/clearSurvey')"
        />
      </div>
    </section>

    <section>
      <div class="half">
        <select-goals-and-tasks 
          :goals="store.getters['invitations/goalsForDropdown'].map(g => ({id: g.id, name: g[`title_${locale.locale.value}`], repetition_rate: g.repetition_rate}))"
          :tasks="store.getters['invitations/tasksForDropdown'].map(t => ({id: t.id, name: t[`title_${locale.locale.value}`]}))"
          :selected-goal="store.state.invitations.selectedGoal"
          :selected-task="store.state.invitations.selectedTask"
          @update:selectedGoal="(v) => {
            store.commit('invitations/selectGoal', v)
          }"
          @click:addGoal="store.commit('invitations/addGoal')"
          @update:selectedTask="(v) => {
            store.commit('invitations/selectTask', v)
          }"
          @click:addTask="store.commit('invitations/addTask')"
        ></select-goals-and-tasks>
      </div>
      <div class="half">
        <h3>{{ $t('default.invitations.goalsandtasks.selected') }}</h3>
        <item-list :items="selectedGoalsAndTasks" />
      </div>
    </section>

    <section>
      <div class="half">
        <select-recipients
          v-if="store.state.organization.groups && store.state.organization.members"
          :groups="store.state.organization.groups?.map(g => ({id: g.id, name: g.name}))"
          :selected-group="store.state.invitations.selectedGroup"
          @update:selectedGroup="(v) => {
            store.commit('invitations/selectGroup', v)
          }"
          :members="store.state.invitations.selectedGroup ? 
                      store.state.organization.members
                        .filter(m => m.organization_group===store.state.invitations.selectedGroup)
                        .filter(m => !store.state.invitations.selectedMembers.includes(m.id)) : 
                      []
          "
          @addMember="(memberId) => {
            store.commit('invitations/addMember', memberId)
          }"
          :new-members="newMembers"
          @update:newMembers="v => newMembers=v"
          @addNewMembers="handleAddNewMembers"
          :new-members-has-errors="newMembersHasErrors"
        />
      </div>
      <div class="half">
        <h3>{{ $t('default.invitations.recipients.selected') }}</h3>
        <item-list :items="selectedRecipients" />
      </div>
    </section>

    <section>
      <div class="half">
        <h3>{{ $t('default.invitations.additional-content.title') }}</h3>
        <div class="content-container">
          <div class="title">
            {{ $t('default.invitations.additional-content.subtitle') }}
          </div>
          <div class="content">
            <textarea
              class="w-full bg-gray-100 additional-message"
              cols="100"
              :model-value="store.state.invitations.additionalMessage"
              @update:modelValue="(v) => StorageEvent.commit('invitations/setAdditionalMessage', v)"
            ></textarea>
            <div class="text-gray-400 text-sm mt-1 flex align-center flex items-center">
              {{ $t('default.invitations.additional-content.info') }}
            </div>
          </div>
        </div>
      </div>
    </section>

    <section>
      <div class="half">
        <h3>{{ $t('default.invitations.summary.title') }}</h3>
        <span>{{ $t('default.invitations.summary.content') }}</span>
        <ul class="invitations-summary-list">
          <li v-if="store.state.invitations.selectedSurvey">
            {{ $t('default.invitations.summary.survey') }} 
            {{ surveysForDropdown.filter(s => s.id === store.state.invitations.selectedSurvey).at(0)?.name }}
          </li>
          <li v-for="goalOrTask, idx of selectedGoalsAndTasks" :key="`summary-gt-${idx}`">
            {{ goalOrTask.name }}
          </li>
        </ul>
      </div>
    </section>

    <section v-if="store.state.invitations.selectedDate">
      <div class="half">
        <Alerts 
          :alertData="[`${$t('default.invite.schedule_update')} ${nativeSelectedDate}`]"
          :alertHeading="$t('default.invite.schedule_update_heading')"
          :closable="true"
          :onClose="() => store.commit('invitations/setSelectedDate', null)"
          :isError="false" />
      </div>
    </section>

    <section v-if="submitError">
      <div class="half">
        <Alerts 
          :alertData="[errorMsg]"
          :alertHeading="$t('default.invitations.error.title')"
          :closable="true"
          :onClose="() => submitError=false"
          :isError="true" />
      </div>
    </section>

    <section v-if="submitSuccess">
      <div class="half">
        <Alerts 
          :alertData="[successMsg]"
          :alertHeading="$t('default.invitations.success.title')"
          :closable="true"
          :onClose="() => submitSuccess=false"
          :isError="false" />
      </div>
    </section>

    <section>
      <div class="half">
        <div class="flex justify-end">
          <button class="green mb-3 big send-invites-btn" type="button" @click.prevent="submit()">
            {{ $t('default.invite.invitation_button') }}
          </button>
          <span
            class="watch-icon group cursor-pointer relative inline-block w-28 text-center shadow-lg">
            <Datepicker 
              :model-value="store.state.invitations.selectedDate"
              @update:model-value="(d) => {
                console.log('Selected date was: ', store.state.invitations.selectedDate)
                console.log('Updating to: ', d)
                store.commit('invitations/setSelectedDate', d)
              }"
              ref="datepicker"
              :minDate="new Date()"
              format="dd.MM.yyyy H:mm"
              minutes-increment="5"
              :start-time="{ hours: 0, minutes: 0 }"
              :clearable="false"
            />
            <span class="opacity-0 group-hover:opacity-100 pointer-events-none">
              {{ $t('default.invite.schedule_tooltip') }}
            </span>
          </span>
        </div>
      </div>
    </section>

  </MainViewContainer>
</template>

<style>
.invitations-view {
  & section {
    /* use apply here to keep the code to minimum */
    @apply col-span-12;
    @apply grid;
    @apply grid-cols-12;
    gap: 4em;
    margin-top: 3em;

    & .half {
      @apply col-span-12;
      @apply lg:col-span-6;
    }
  }

  .content-container {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 2em;
    align-items: center;
    margin-top: 2em;

    .title {
      grid-column: 1;
    }

    .content {
      grid-column: 2 / span 4;

      textarea {
        @apply bg-gray-200;
        @apply border;
        @apply border-gray-300;
        resize: none;
      }

      .additional-message {
        height: 7em;
      }
    }
  }

  .invitations-summary-list {
    li {
      list-style-type: disc;
      list-style-position: inside;
    }
  }

  .send-invites-btn {
    padding: 0.85rem 0.8rem 0.85rem 2rem;
    border-radius: 3rem;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    height: 48px;
  }

  .watch-icon {
    display: flex;
    background: #00d1b5;
    width: 49px;
    height: 48px;
    align-items: center;
    border-top-right-radius: 3rem;
    border-bottom-right-radius: 3rem;
    position: relative;
    border-left: 1px dashed #fff;
  }
  
  .watch-icon img {
    width: 25px;
    height: 25px;
    position: absolute;
    left: 10px;
    cursor: pointer;
  }

  .watch-icon .pointer-events-none {
    background: black;
    color: white;
    font-size: 12px;
    text-align: center;
    border-radius: 6px;
    width: 160px;
    padding: 5px 10px;
    z-index: 99;
    position: absolute;
    right: -55px;
    top: -25px;
  }

  .watch-icon input {
    padding: 0 !important;
    opacity: 0;
    width: 42px;
    cursor: pointer;
  }

  input::-webkit-calendar-picker-indicator {
    cursor: pointer;
    font-size: 40px;
    pointer-events: auto;
  }

  .datetime {
    background: #f6f6f6;
    padding: 20px 20px 20px 20px;
    font-size: 17px;
    color: #222;
    font-weight: 500;
    border: 1px solid #00d1b5;
    margin-top: 1rem;
  }

  .datetime span {
    font-weight: bold;
    font-size: 16px;
    color: #00d1b5;
    font-weight: 500;
    padding-left: 15px;
  }

  .dp__input_icon {
    color: white;
  }
}
</style>
