<script setup>
import { computed } from 'vue'
import DropDown2 from '@/components/DropDown2.vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

const props = defineProps({
  surveys: Array
})

const selectedSurvey = defineModel('selectedSurvey')
const surveyAdditionalMessage = defineModel('surveyAdditionalMessage')

const survey = computed(() => (
  props.surveys.filter(s => s.id===selectedSurvey.value).at(0)
))

const emit = defineEmits(['clearSurvey'])
</script>

<template>
  <h3>{{ $t('default.invitations.survey.title') }}</h3>
  <p>{{ $t('default.invitations.survey.ingress') }}</p>

  <div class="content-container">
    <div class="title">
      {{ $t('default.invitations.survey.title') }}
    </div>
    <div class="content">
      <drop-down-2
        :options="surveys"
        v-model="selectedSurvey"
        :placeholder="$t('default.invitations.survey.select')"
        class="w-9/12"
      />
      <div
        v-if="selectedSurvey"
        class="survey-clear-icon"
        @click="emit('clearSurvey')"
      >
        <FontAwesomeIcon icon="fa-solid fa-x" />
      </div>
      <div class="text-gray-400 text-sm mt-1 flex align-center flex items-center">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M16 8V16M12 11V16M8 14V16M6 20H18C18.5304 20 19.0391 19.7893 19.4142 19.4142C19.7893 19.0391 20 18.5304 20 18V6C20 5.46957 19.7893 4.96086 19.4142 4.58579C19.0391 4.21071 18.5304 4 18 4H6C5.46957 4 4.96086 4.21071 4.58579 4.58579C4.21071 4.96086 4 5.46957 4 6V18C4 18.5304 4.21071 19.0391 4.58579 19.4142C4.96086 19.7893 5.46957 20 6 20Z"
            stroke="#3F3F46" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
        <template v-if="survey">
          {{ $t('default.send_survey.survey_questions', { total_questions: survey.questionCount, survey_name: survey.name }) }}
        </template>
      </div>
    </div>
    <div class="title">
      {{ $t('default.invitations.survey.additional-message') }}
    </div>
    <div class="content">
      <textarea
        v-model="surveyAdditionalMessage"
        class="w-full bg-gray-100 additional-message"
      ></textarea>
      <div class="text-gray-400 text-sm mt-1 flex align-center flex items-center">
        {{ $t('default.invitations.survey.additional-message-info') }}
      </div>
    </div>
  </div>
</template>

<style>
.survey-clear-icon {
  color: var(--halsa-red);
  cursor: pointer;
  display: inline;
  padding-left: 1em;
  font-size: 0.8em;
}
</style>