import './assets/index.scss'

import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import VueAxios from 'vue-axios'
import i18n from './i18n'
import { library } from '@fortawesome/fontawesome-svg-core'

import { 
  faTrash,
  faPlusCircle,
  faCircleCheck,
  faListCheck,
  faX,
} from '@fortawesome/free-solid-svg-icons'

library.add([
  faTrash,
  faPlusCircle,
  faCircleCheck,
  faListCheck,
  faX,
])

createApp(App)
  .use(store)
  .use(router)
  .use(VueAxios, axios)
  .use(i18n)
  .use(library)
  .mount('#app')
