<script setup>
  const selected = defineModel("modelValue")
  const props = defineProps({
    options: Array,
    placeholder: String,
  })
</script>

<template>
  <select
    class="border border-gray-300 text-gray-600 h-10 pl-5 pr-10 bg-gray-200 hover:border-gray-400 focus:outline-none appearance-none"
    v-model="selected"
  >
    <option :value="null" disabled selected hidden>{{ placeholder }}</option>
    <option v-for="option in props.options" :key="option.id" :value="option.id">
      {{ option.name }}
    </option>
  </select>
</template>
