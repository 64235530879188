<template>
  <MainViewContainer :showBackIcon="true">
    <div class="col-span-12 md:col-span-7">
      <h1>
        {{ surveyName }}
      </h1>
      <div class="lg:flex mt-6 justify-between">
        <h2 class="w-full lg:w-1/3">{{ $t("default.survey.results") }}</h2>
        <div>
          <select v-model="selectedGroupId">
            <option key="all" value="all">
              {{ $t("default.survey.all_members") }}
            </option>
            <option v-for="group in state.defaultGroups" :key="group.id" :value="group.id">
              {{ group.name }}
            </option>
          </select>
        </div>
      </div>
      <div class="grid grid-cols-3">
        <div class="flex">
          <Loader v-if="loading"></Loader>
        </div>
      </div>
      <div class="grid grid-cols-12 gap-1 mt-10 mb-6">
        <div class="col-span-12" v-if="surveyResult['survey_questions']">
          <SurveySummary v-if="Object.keys(surveyResult['category_grouping']).length !== 0 &&
    surveyResult['display_summary'] !== 0
    " :category_grouping="surveyResult['category_grouping']" :display_summary="surveyResult['display_summary']"
            :questions="surveyResult['survey_questions']
    .filter((q) => q['question']['type'] === 'SC')
    .map((q) => q['question'])
    " :clang="state.clan" title="Survey Summary" />

          <hr class="col-span-8 col-start-3" />

          <CategoryResults v-for="(category, i) in categoriesWithSummaries" :questions="surveyResult['survey_questions'].filter(
    (x) =>
      x['question']['answers'].length &&
      x['question']['type'] === 'SC' &&
      x['question']['category'] &&
      x['question']['category']['id'] === category['id']
  )
    " :category="category" :index="i" :key="`category-${category['id']}`" :clang="state.clan"
            title="Category Summary" />

          <hr class="col-span-8 col-start-3" v-if="categoriesWithSummaries.length" />

          <div v-for="(result, categoryName) in questionsPerCategory" :key="categoryName">
            <h3 class="mt-10">{{ catTrans(result[1]) }}</h3>

            <template v-for="(question, i) in result" :key="question['id']">
              <template v-if="question['question']['answers'].length">
                <ChoiceQuestionResult v-if="question['question']['type'] === 'SO'" :question="question['question']"
                  index="" :clang="state.clan" :class="{ 'bg-gray-200': i % 2 }" title="Choice Question">
                </ChoiceQuestionResult>

                <ScaleQuestionResult v-else-if="question['question']['type'] === 'SC'" :question="question['question']"
                  index="" :clang="state.clan" :class="{ 'bg-gray-200': i % 2 }" title="Scale Question">
                </ScaleQuestionResult>

                <OpenQuestionResult v-else-if="['FT', 'ST'].indexOf(question['question']['type']) > -1
    " :question="question['question']" index="" :clang="state.clan" :class="{ 'bg-gray-200': i % 2 }"
                  title="Open Question">
                </OpenQuestionResult>

                <NPSQuestionResult v-else-if="question['question']['type'] === 'NPS'" :question="question['question']"
                  index="" :clang="state.clan" :class="{ 'bg-gray-200': i % 2 }" title="NPS Question">
                </NPSQuestionResult>
              </template>
            </template>
          </div>
        </div>
      </div>
    </div>
    <div class="col-span-10 md:col-start-9 md:col-span-3 md:ml-10">
      <h3>
        {{ $t("default.survey.answers") }}
      </h3>
      <table class="shadow-lg bg-white table-auto w-full"
        v-if="surveyResult['responses'] && surveyResult['responses'].length">
        <tr :class="i % 2 ? 'bg-gray-100' : ''" v-for="(member, i) in surveyResult['responses'].filter(
    (m) => m.status === 'Answered'
  )" :key="`${member['id']}-${i}-answered`">
          <td class="px-8 py-4">
            <router-link :to="{ name: 'userResults', params: { usr: member['user_id'] } }">
              {{ member["user_name"] }}
            </router-link>
          </td>
        </tr>
      </table>
      <p class="text-sm text-black-900" v-else>
        {{ $t("default.survey.no_data") }}
      </p>
      <h3 class="mt-10">
        {{ $t("default.survey.invited") }}
      </h3>
      <table class="shadow-lg bg-white table-auto w-full"
        v-if="surveyResult['responses'] && surveyResult['responses'].length">
        <tr :class="i % 2 ? 'bg-gray-100' : ''" v-for="(member, i) in surveyResult['responses'].filter(
    (m) => m.status === 'Sent'
  )" :key="member['id']">
          <td class="px-8 py-4">
            <router-link :to="{ name: 'userResults', params: { usr: member['user_id'] } }">
              {{ member["user_name"] }}
            </router-link>
          </td>
        </tr>
      </table>
      <p class="text-sm text-black-900" v-else>
        {{ $t("default.survey.no_data") }}
      </p>
      <span class="w-1/4"></span>
      <router-link v-if="state.organization" class="button green mt-4 print-hide" :to="{
    name: 'sendSurvey', params: {
      org: state.organization.id,
      id: surveyResult.id,
      whereToGo: 'surveyResults',
    },
  }">
        {{ $t("default.survey.send_to_more") }}
      </router-link>

      <a v-if="isAdminUser" class="button green mt-4 print-hide" @click="downloadReport" target="_blank">
        {{ $t('default.dashboard.download_org_report') }}
        <img src="../assets/svg/presentation-chart-bar.svg" />
      </a>

      <a class="button green mt-4 print-hide" @click="printPage">
        {{ $t('default.common.print') }}
        <img class="icon-print" src="../assets/svg/print.svg" />
      </a>

    </div>
  </MainViewContainer>
</template>

<script>
import axios from '@/axios-instance'
import MainViewContainer from "@/components/MainViewContainer";
import ChoiceQuestionResult from "@/components/ChoiceQuestionResult";
import ScaleQuestionResult from "@/components/ScaleQuestionResult";
import NPSQuestionResult from "@/components/NPSQuestionResult";
import OpenQuestionResult from "@/components/OpenQuestionResult";
import SurveySummary from "@/components/SurveySummary";
import CategoryResults from "@/components/CategoryResults";
import { computed, onMounted, reactive, ref } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import Loader from "../components/Loader";
import translator from "../mixins/translator";

export default {
  name: "Survey",
  components: {
    MainViewContainer,
    ChoiceQuestionResult,
    ScaleQuestionResult,
    OpenQuestionResult,
    NPSQuestionResult,
    SurveySummary,
    CategoryResults,
    Loader,
  },
  setup() {
    const route = useRoute();
    const surveyId = route.params.id;
    const orgId = route.params.org;
    const store = useStore();
    const loading = ref(true);
    const reactiveWidth = ref(screen.width);
    const selectedGroupId = ref("all");

    const state = reactive({
      organization: computed(() => store.state.organization.organization),
      defaultGroups: computed(
        () => store.state.organization.defaultGroups
      ),
      clan: computed(() => store.state.survey.currentLanguage),
    });

    window.onresize = () => {
      reactiveWidth.value = window.innerWidth;
    };

    return {
      state,
      surveyId,
      orgId,
      loading,
      reactiveWidth,
      selectedGroupId,
    };
  },

  mounted() {
    this.$store.dispatch(
      "organization/fetchOrganization",
      this.$route.params.org
    );
    this.setupData();
  },

  mixins: [translator],

  methods: {
    async setupData() {
      await this.$store.dispatch("organization/fetchGroups", this.orgId);
      if (this.state.defaultGroups && this.state.defaultGroups.length === 0)
        return;

      if (
        this.selectedGroupId === undefined ||
        this.selectedGroupId === "all"
      ) {
        this.$store.dispatch("results/fetchOrgSurveyAnswers", {
          orgId: this.orgId,
          surveyId: this.surveyId,
        });
      } else {
        this.$store.dispatch("results/fetchOrgSurveyAnswers", {
          groupId: this.selectedGroupId,
          surveyId: this.surveyId,
        });
      }
    },
    async downloadReport() {
      const currentParams = this.$router.currentRoute.value?.params
      const currentGroup = this.selectedGroupId
      let url = `/api/v1/surveys/organization/${currentParams.org}/surveys/${currentParams.id}/pptreport/?format=ppt`
      if (currentGroup !== "all") {
        url += `&group_id=${currentGroup}`
      }
      try {
        await axios.post('/auth/user/set_cookie/')
        window.location.href = url
      } catch (e) {
        console.error('Error in setting cookie: ', e)
      }
    },
    groupBy(objectArray, property) {
      return objectArray.reduce(function (acc, obj) {
        let key = obj[property];
        if (!acc[key]) {
          acc[key] = [];
        }
        acc[key].push(obj);
        return acc;
      }, {});
    },
    catTrans(result) {
      const no_cat = {
        sv: "Ingen kategori",
        en: "No category",
        fi: "Ei luokkaa",
      };
      if (!result) {
        return no_cat[this.state.clan];
      }
      if (result.question === undefined || result.question === null) {
        return no_cat[this.state.clan];
      }
      const category = result.question.category;
      if (category === null) {
        return no_cat[this.state.clan];
      }
      return category.translations.length === 0
        ? category["name"]
        : this.translator(this.state.clan, category);
    },
    printPage() {
      window.print()
    },
  },

  computed: {
    isAdminUser() {
      return this.$store.getters['user/isAdminUser']
    },
    surveyResult() {
      if (
        this.selectedGroupId !== undefined &&
        this.selectedGroupId !== "all"
      ) {
        if (this.$store.state.results.groupSurveyData[this.surveyId]) {
          if (
            this.$store.state.results.groupSurveyData[this.surveyId][
            this.selectedGroupId
            ]
          ) {
            return this.$store.state.results.groupSurveyData[this.surveyId][
              this.selectedGroupId
            ];
          }
        }
      } else {
        if (this.$store.state.results.orgSurveyData[this.surveyId]) {
          if (
            this.$store.state.results.orgSurveyData[this.surveyId][this.orgId]
          ) {
            return this.$store.state.results.orgSurveyData[this.surveyId][
              this.orgId
            ];
          }
        }
      }
      return {};
    },
    surveyName() {
      if (this.surveyResult !== undefined) {
        return this.surveyResult.name;
      } else {
        return this.surveyId;
      }
    },
    orgName() {
      return this.state.organization ? this.state.organization["name"] : "";
    },
    categoryIds() {
      return Object.keys(this.surveyResult["category_grouping"]);
    },
    isMobil() {
      if (this.reactiveWidth < 768) {
        return false;
      }
      return true;
    },
    categoriesWithSummaries() {
      const newObj = {};
      const keysToPick = Object.values(this.surveyResult["category_grouping"])
        .filter((cat) => cat["display_summary"] !== 0)
        .forEach((cat) => (newObj[cat.id] = cat));
      return newObj;
    },
    questionsPerCategory() {
      return this.groupBy(
        this.surveyResult.survey_questions.map((item) => {
          return {
            ...item,
            cat: item.question.category
              ? item.question.category.name
              : "No category",
          };
        }),
        "cat"
      );
    },
  },

  watch: {
    surveyResult: {
      handler: function (newResults) {
        if (newResults !== {}) {
          this.loading = false;
        }
      },
    },

    selectedGroupId: {
      handler: function (newGroupId) {
        this.loading = true;
        this.setupData();
      },
    },
  },
};
</script>

<style scoped type="scss">
/** FIXME: should merge these to a base.scss at some point. Also invite.vue has the same */
select,
option {
  padding: 0.5rem 1rem;
  line-height: 1.25;
  border: 2px solid rgba(229, 231, 235, 1);
  min-width: 20rem;
}

.icon-print {
  height: 1.2em;
  width: 1.2em;
}
</style>
