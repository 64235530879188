<template>
  <nav class="navigation-hierarchy bg-grey-light rounded font-sans w-full mb-3">
    <ol class="list-reset flex text-gray-500 text-xs lg:text-base">
      <template v-for="(bit, index) in trailRoute" :key="index" class="flex flex-wrap">
        <span>»</span>
        <li v-if="index < trailRoute.length - 1">
          <a :href="bit[1]" class="font-bold cursor-pointer hover:text-black"> {{ bit[0] }}</a>
        </li>
        <li v-else class="text-black"> {{ bit[0] }} </li>
      </template>
    </ol>
  </nav>
</template>

<script>

import i18n from '../i18n'


// Attempt to translate the name of a route. By default we just
// fetch the translation string under "navigation" but there are
// cases where we'd rather want a name from the available objects,
// like organization, group or user names.
function translateName(pathName, currentParams, store) {
  if (pathName === "organization") {
    const org = store.state.organization?.organization
    if (org) {
      return `${org?.name}`
    }
  } else if (pathName === "surveyResults") {
    const surveyId = currentParams.survey || currentParams.id
    const orgId = parseInt(currentParams.org)
    let allSurveys = null
    let survey = null
    if (orgId) {
      allSurveys = store.state.results?.orgSurveyData[surveyId]
      survey = allSurveys ? allSurveys[orgId] : null
    } else {
      const userId = parseInt(currentParams.usr)
      allSurveys = store.state.results?.userSurveyData[surveyId]
      survey = allSurveys ? allSurveys[userId] : null
    }
    if (survey) {
      return `${survey?.name}`
    }
  } else if (pathName === "members") {
    const groupId = parseInt(currentParams.group)
    const groups = store.state.organization?.groups
    const group = groups ? groups.find(g => g.id === groupId) : null
    if (group) {
      return `${group?.name}`
    }
  } else if (pathName === "member-results") {
    const userId = parseInt(currentParams.usr)
    const users = store.state.organization?.members
    const user = users ? users.find(u => u.user_id === userId) : null
    if (user) {
      return `${user?.first_name} ${user?.last_name}`
    }
  } else if (pathName === "userResults") {
    const userId = parseInt(currentParams.usr)
    const users = store.state.organization?.members
    const myId = store.state.user?.user?.id
    const user = users ? users.find(u => u.user_id === userId) : null
    if (user && myId !== userId) {
      return `${user?.first_name} ${user?.last_name}`
    }
  }
  return i18n.global.t(`default.navigation.${pathName}`)
}

function buildHierarchy(navBits, currentParams, store, router) {
  if (navBits?.length > 0) {
    return navBits.map((pathName, index) => {
      const name = translateName(pathName, currentParams, store)
      const fullLink = index < navBits.length - 1 ? router.resolve({ name: pathName, params: currentParams }) : { fullPath: "" }
      return [name, fullLink.fullPath]
    })
  }
  return []
}

export default {
  computed: {
    trailRoute() {
      const navPath = this.$router.currentRoute.value?.meta?.navPath
      const currentParams = this.$router.currentRoute.value?.params
      try {
        return buildHierarchy(navPath, currentParams, this.$store, this.$router)
      } catch (e) {
        console.error("Caught NavigationHierarchy build fail:", e)
        return []
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.navigation-hierarchy {
  ol {
    span {
      display: inline-block;
      margin: 0 4px;
    }
  }
}
</style>
